import React, { ReactElement } from "react";
import { graphql } from "gatsby";
import { AppOverlayProvider, AppProvider } from "@Context/AppContext";
import SEO from "@Components/SEO";
import Layout from "@Components/Layout";
import ContentfulModule from "@Src/components/ContentfulModule";
import { IArticlePage, IPageModule } from "@Types/ContentfulTypes";
import BlockLayout from "@Src/components/BlockLayout";
import "@GraphQL/fragments";

export interface Props {
  location: {
    pathname: string;
  };
  data: {
    contentfulArticlePage: IArticlePage;
  };
}

const ArticlePage = ({ data, location }: Props): ReactElement => {
  const { pageModules, title, seoSettings } = data.contentfulArticlePage;

  return (
    <AppProvider type="article" path={location.pathname}>
      <AppOverlayProvider>
        <Layout>
          <SEO
            location={location.pathname}
            title={seoSettings?.title || title}
            description={seoSettings?.description}
            // meta={seoSettings?.metaTags}
            image={seoSettings?.image}
          />
          <BlockLayout>
            {pageModules &&
              pageModules.map(
                (module: IPageModule): ReactElement => (
                  <ContentfulModule
                    key={module.id}
                    type={module.type}
                    data={module}
                  />
                )
              )}
          </BlockLayout>
        </Layout>
      </AppOverlayProvider>
    </AppProvider>
  );
};

export default ArticlePage;

export const query = graphql`
  query ($id: String!) {
    contentfulArticlePage(id: { eq: $id }) {
      ... on WPPageNode {
        ...PageFields
        ... on WPArticlePageNode {
          pageModules {
            ... on WPPageModuleNode {
              ...IFrameModule
              ...InfographicsModule
              ...TextBackgroundImageModule
              ...TextImageModule
              ...TextModule
              ...MediaModule
              ...VideoModule
              ...RichTextModule
              ...PersonalCards
              ...ProductCards
              ...ArticleCards
              ...PartnerProducts
              ...CtaModule
              ...QuoteSlider
              ...PressReleases
              ...RSSFeedCards
              ...ColumnModule
            }
          }
        }
      }
    }
  }
`;
